import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {

  registerUserData;
  isDisabled = false;
  hide = true;
  ConfirmHide = true;
  sports;
  userDate;
  levelsParsed;
  todaydate = new Date();

  constructor(
    private auth: AuthService,
    private matSnackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit() {
    this.registerUserData = {};
    this.levelsParsed = [];
    this.getSport()
  }

  onSportChange(val) {
    this.registerUserData['sport'] = val;
    this.getSportLevel()
  }
  registerUser() {
    console.log('registerUserData', this.registerUserData);
    this.isDisabled = true;
    this.auth.registerUser(this.registerUserData).subscribe(
      (res: any) => {
        this.isDisabled = false;
        this.matSnackBar.open('Usuário cadastrado com sucesso!!', 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 1000);
      },
      (err: any) => {
        this.isDisabled = false;
        err.error.message.forEach(el => {
          this.matSnackBar.open(el, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });

        })

      }
    );
  }

  getSportLevel() {
    this.auth.getSportLevel(this.registerUserData['sport']).subscribe(
      res => {
        this.levelsParsed = JSON.parse(res.message[0].levels);
      },
      err => {
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }


  dateChanged($event) {
    this.userDate = formatDate($event.target.value, 'YYYY-MM-dd', 'en-US')
    this.registerUserData['dob'] = this.userDate;
    console.log('dob', this.userDate);
  }

  onGenderChange(genderVal) {
    console.log('genderVal' + genderVal);
    this.registerUserData['gender'] = genderVal;
  }

  onSportlevelChange(sportLevelVal) {
    console.log('sportLevelVal' + sportLevelVal);
    this.registerUserData['level'] = sportLevelVal;
  }

  getSport() {
    this.auth.getSportList().subscribe(
      res => {
        this.sports = res['message'];
        console.log(this.sports);
      },
      err => {
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        console.log('error sports ', err);
      }
    );
  }

}
