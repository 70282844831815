import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-tournament',
  templateUrl: './tournament.component.html',
  styleUrls: ['./tournament.component.scss'],
})
export class TournamentComponent implements OnInit {

  tournamentLoading = true;
  tournament;

  constructor(
    private courtService: GetCourtService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.tournament=[];
    this.route.params.subscribe(param => {
      this.getTournaments();
    });
  }

  getTournaments() {
    this.courtService.getTournamentList().subscribe(
      res => {
        this.tournamentLoading = false;
        this.tournament = res['message'];
      },
      err => {
        this.tournamentLoading = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }

}
