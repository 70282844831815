import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './common/footer/footer.component';
import { HeaderComponent } from './common/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { SignupModule } from './pages/signup/signup.module';
import { RouterModule } from '@angular/router';
import {ContactComponent} from './pages/contact/contact.component';
import {CourtDetailModule} from './pages/court-detail/court-detail.module';
import {PageNotFoundComponent} from './common/page-not-found/page-not-found.component';
import {MaterialModule} from './material.module';
import { CreateMatchComponent, matchPayment } from './pages/create-match/create-match.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AboutComponent } from './pages/about/about.component';
import { OpenMatchesListComponent } from './pages/open-matches-list/open-matches-list.component';
import { ViewProfileComponent } from './pages/view-profile/view-profile.component';
import { BookingHistoryComponent } from './pages/booking-history/booking-history.component';
import { ClubListComponent, confirmMatch } from './pages/club-list/club-list.component';
import { ClubComponent, filterModal, payByRefrence } from './pages/club/club.component';
import { DATE_FORMATS } from 'src/app/common/date-formats';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MessagingComponent } from './pages/messaging/messaging.component';
import { PlayersComponent } from './pages/players/players.component';
import { PlayerDetailComponent } from './pages/player-detail/player-detail.component';
import { FriendsComponent } from './pages/friends/friends.component';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { NewsComponent } from './pages/news/news.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { JoinMatchComponent } from './pages/join-match/join-match.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { PlayerFilterPipe } from './pipes/player-filter.pipe';
import { LocationComponent } from './pages/location/location.component';
import { SportListComponent } from './pages/sport-list/sport-list.component';
import { BookingDetailComponent, share } from './pages/booking-detail/booking-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TournamentComponent } from './pages/tournament/tournament.component';
import { MatchDetailComponent } from './pages/match-detail/match-detail.component';
import { LoginModule } from './pages/login/login.module';

@NgModule({
  declarations: [
    PlayerFilterPipe,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    HomeComponent,
    ViewProfileComponent,
    AboutComponent,
    OpenMatchesListComponent,
    PageNotFoundComponent,
    CreateMatchComponent,
    BookingHistoryComponent,
    ClubListComponent,
    ClubComponent,
    MessagingComponent,
    PlayersComponent,
    ForgotPasswordComponent,
    matchPayment,
    PlayerDetailComponent,
    MatchDetailComponent,
    FriendsComponent,
    ViewRequestsComponent,
    NewsComponent,
    RankingComponent,
    JoinMatchComponent,
    TournamentComponent,
    EditProfileComponent,
    LocationComponent,
    SportListComponent,
    filterModal,
    confirmMatch,
    share,
    payByRefrence,
    BookingDetailComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    LoginModule,
    MaterialModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SwiperModule,
    RouterModule,
    SignupModule,
    CourtDetailModule,
    FormsModule,
    NgbModule
  ],
  exports:[MaterialModule],
  providers: [{
    provide: [LocationStrategy, MAT_DATE_FORMATS], 
    useClass: HashLocationStrategy,
    useValue: DATE_FORMATS 
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
