import { Component, Inject, NgZone, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  loginUserData;
  isDisabled = false;
  user: SocialUser;
  loggedIn: boolean;
  gUser;

  constructor(
    ngZone:NgZone,
    private metaservice: Meta,
    @Inject(DOCUMENT) private doc:Document,
    private renderer: Renderer2,
    private auth: AuthService,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private socialAuth: SocialAuthService
  ) { 
      window['onSignIn'] = user => ngZone.run(
        ()=>{
        console.log('user',user);
        
        this.afterSignUp(user);
      });

  }

  ngOnInit() {
    this.loginUserData = {};
    this.metaservice.addTags([
      {name:'google-signin-client_id',content:'80525686508-b79h3ephrs96th212pbps3nl5cprolam.apps.googleusercontent.com'}
    ]);
    let script = this.renderer.createElement('script');
    script.src="https://apis.google.com/js/platform.js";
    script.defer = true;
    script.async = true;
    this.renderer.appendChild(document.body,script);
    this.socialAuth.authState.subscribe((user) => {
      this.user = user;
    });
  }
  afterSignUp(googleUser){
    this.gUser=googleUser;
    console.log('this.gUser',this.gUser);
    
  }

  loginUser() {
    this.isDisabled = true;
    this.auth.loginUser(this.loginUserData).subscribe(
      res => {
        this.isDisabled = false;
        this.matSnackBar.open(res.data.first_name + ' Conectado com sucesso!!', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
        this.router.navigateByUrl('/location');
      },
      (err: any) => {
        this.isDisabled = false;
        this.matSnackBar.open('Credenciais inválidas!!', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
      }
    );
  }
  
  // signInWithGoogle(): void {
    // this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log('x ==>', x));

    // this.socialAuth.authState.subscribe((user) => {
    //   console.log('user',user);
    //   let socialLoggedData = {
    //     social: "FACEBOOK",
    //     first_name: user.firstName,
    //     last_name: user.lastName,
    //     email: user.email,
    //     password: user.id,
    //     mobile: "0000000"
    //   }
    //   this.auth.socialLoginUser({ socialUserData: socialLoggedData }).subscribe(
    //     res => {
    //       console.log('logged res ', res);
    //       this.matSnackBar.open(res.data.first_name + ' logged in successfully!!', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
    //       this.router.navigateByUrl('/location');
    //     },
    //     err=>{
    //       this.matSnackBar.open('Unauthorized', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });

    //     }
    //   );

    // })
  // }


  facebookLogin() {
    this.socialAuth.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.socialAuth.authState.subscribe((user) => {
      let socialLoggedData:{} = {
        social: user.provider,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email?user.email:user.id,
        password: user.id,
        mobile: "0000000000"
      }
      this.auth.socialLoginUser({ socialUserData: socialLoggedData }).subscribe(
        res => {
          this.matSnackBar.open(res.data.first_name + ' Conectado com sucesso!!', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
          this.router.navigateByUrl('/location');
        },
        err=>{
          this.matSnackBar.open('Unauthorized', 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });

        }
      );

    })
  }


}
