import { Component, OnInit, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.scss'],
})
export class BookingDetailComponent implements OnInit {

  bookingId: Number;
  bookingData;
  bookingDataLoad = false;
  singleCourt;
  paymentButton = false
  isDisabled = false;
  ShareByUrl = "Compartilhar por URL";
  currentPage = window.location.href;
  amount;

  constructor(
    private bookingDetail: GetCourtService,
    private route: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {

    this.route.params.subscribe(param => {
      this.bookingId = +this.route.snapshot.queryParamMap.get('id');
      this.getUpcomingDetail();
    });

  }

  shareUrl() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (window.location.href));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.ShareByUrl = "Copied!!";
  }
  openUrl(url) {
    window.open(url, '_blank', 'location=no');
  }

  payNow() {
    this.paymentButton = true
    const payAgainData = {
      order_email: JSON.parse(localStorage.getItem('loggedInUserData'))._email,
      booking_id: this.route.snapshot.queryParamMap.get('id'),
      mobile: JSON.parse(localStorage.getItem('loggedInUserData'))._mobile,
      amount: this.amount
    }
    
    this.bookingDetail.paymentByGpo(payAgainData).subscribe(
      res => {
        this.matSnackBar.open(
          'Please do the payment at merchant payment page',
          'Close',
          { duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
        this.paymentButton = false;
        window.location.href = 'https://reservatti.com/payment/payment.php?paymenturl='+res['paymentUrl'];
        // this.dialogRef.close();
      },
      err => {
        // paymentData = '';
        this.paymentButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )

  }

  copyMessage() {
    this.dialog.open(share, {
    });
  }


  cancelBooking() {
    this.isDisabled = true;
    const cancelParam = {
      'booking_id': this.bookingId
    }
    this.bookingDetail.cancelBooking(cancelParam)
      .subscribe((upcomingDet) => {
        this.isDisabled = false;
        this.matSnackBar.open(upcomingDet['message'], 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
        setTimeout(() => {
          this.router.navigateByUrl('/location');
        }, 3000);
      },
        error => {
          this.matSnackBar.open(error.error.message, 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
          this.isDisabled = false;
        }
      );
  }

  getUpcomingDetail() {
    this.bookingDataLoad = true;
    this.bookingDetail.getBookingDetails(this.bookingId)
      .subscribe((upcomingDet) => {
        this.bookingDataLoad = false;
        this.bookingData = upcomingDet['message'];
        upcomingDet['message'].forEach((singleCourtData) => {
          const imageParsed = JSON.parse(singleCourtData.court.image_location);
          this.singleCourt = Object.values(imageParsed);
          this.amount=singleCourtData.amount;
        });
      },
        error => {
          this.bookingDataLoad = false;
        }
      );
  }


}






@Component({
  selector: 'share.html',
  templateUrl: 'share.html',
})
export class share {

  shareEmail = '';
  shareButton = false;

  constructor(
    public dialogRefrence: MatDialogRef<share>,
    private shareService: GetCourtService,
    private matSnackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {

  }
  shareDetail() {
    const shareData = {
      url: window.location.href,
      email: this.shareEmail
    }
    this.shareButton = true;
    this.shareService.shareBooking(shareData)
      .subscribe((res) => {
        this.shareButton = false;
        this.matSnackBar.open('Booking Shared sucessfully!!', 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.dialogRefrence.close();
      },
        error => {
          this.shareButton = false;
          this.matSnackBar.open(error.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });

        }
      );

  }

}