import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PlayersService } from 'src/app/services/players/players.service';

@Component({
  selector: 'app-view-requests',
  templateUrl: './view-requests.component.html',
  styleUrls: ['./view-requests.component.scss'],
})
export class ViewRequestsComponent implements OnInit {

  allRequestData;
  isplayersLoaded = true;
  requestActionData;
  requestActiontButton = false;

  constructor(
    private playerData: PlayersService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.requestActionData={};
    this.allRequestData = [];
    this.route.params.subscribe(param => {
      this.getRequest();
    });
    setInterval(()=>{
      this.getRequest();
    },9000);
  }

  getRequest() {
    this.playerData.getRequestService()
      .subscribe(
        res => {
          this.allRequestData = res;
          this.isplayersLoaded = false;
        },
        err => {
          this.allRequestData=[]
          console.log('error getOpenMatches ', err);
          this.isplayersLoaded = false;
        }
      );
  }



  requestAction(requestValue, playerId) {
     this.requestActionData['request_status'] = requestValue;
    const send_id = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    const recieverID = playerId;
    this.requestActionData['sender_id'] = recieverID;
    this.requestActionData['receiver_id'] = send_id;
    this.requestActiontButton = true;
    this.playerData.actionRequestService(this.requestActionData).subscribe(
      res => {
        this.requestActionData['receiver_id'] = '';
        this.matSnackBar.open(
          res['message'],
          'Close',
          {
            duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
        this.requestActiontButton = false;
        this.getRequest();
      },
      err => {
        console.log(err);
        this.requestActionData['receiver_id'] = '';
        this.requestActiontButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )
  }


}
