import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CourtDetailComponent } from './pages/court-detail/court-detail.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { AboutComponent } from './pages/about/about.component';
import { OpenMatchesListComponent } from './pages/open-matches-list/open-matches-list.component';
import { CreateMatchComponent } from './pages/create-match/create-match.component';
import { AuthGuard } from './auth/auth.guard';
import { ViewProfileComponent } from './pages/view-profile/view-profile.component';
import { BookingHistoryComponent } from './pages/booking-history/booking-history.component';
import { ClubListComponent } from './pages/club-list/club-list.component';
import { ClubComponent } from './pages/club/club.component';
import { UserLoggedInGuard } from './auth/user-logged-in.guard';
import { MessagingComponent } from './pages/messaging/messaging.component';
import { PlayersComponent } from './pages/players/players.component';
import { PlayerDetailComponent } from './pages/player-detail/player-detail.component';
import { FriendsComponent } from './pages/friends/friends.component';
import { ViewRequestsComponent } from './pages/view-requests/view-requests.component';
import { NewsComponent } from './pages/news/news.component';
import { RankingComponent } from './pages/ranking/ranking.component';
import { JoinMatchComponent } from './pages/join-match/join-match.component';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { PaymentTermsComponent } from './pages/payment-terms/payment-terms.component';
import { LocationComponent } from './pages/location/location.component';
import { SportListComponent } from './pages/sport-list/sport-list.component';
import { BookingDetailComponent } from './pages/booking-detail/booking-detail.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { TournamentComponent } from './pages/tournament/tournament.component';
import { MatchDetailComponent } from './pages/match-detail/match-detail.component';

const routes: Routes = [
  {
    path: 'court-detail',
    canActivate: [AuthGuard],
    component: CourtDetailComponent
  },
  {
    path: 'player-detail',
    canActivate: [AuthGuard],
    component: PlayerDetailComponent
  },
  {
    path: 'location',
    canActivate: [AuthGuard],
    component: LocationComponent
  },
  {
    path: 'friends',
    canActivate: [AuthGuard],
    component: FriendsComponent
  },
  {
    path: 'policy', 
    loadChildren: () => import('./pages/policies/policies.module').then(x => x.PoliciesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tournament',
    canActivate: [AuthGuard],
    component: TournamentComponent
  },
  {
    path: 'requests',
    canActivate: [AuthGuard],
    component: ViewRequestsComponent
  },
  {
    path: 'news',
    canActivate: [AuthGuard],
    component: NewsComponent
  },
  {
    path: 'ranking',
    canActivate: [AuthGuard],
    component: RankingComponent
  },
  {
    path: 'all-courts',
    canActivate: [AuthGuard],
    component: HomeComponent
  },
  {
    path: 'contact',
    canActivate: [AuthGuard],
    component: ContactComponent
  },
  {
    path: 'booking-history',
    canActivate: [AuthGuard],
    component: BookingHistoryComponent
  },
  {
    path: 'match-detail',
    canActivate: [AuthGuard],
    component: MatchDetailComponent
  },
  {
    path: 'clubs',
    canActivate: [AuthGuard],
    component: ClubListComponent
  },
  {
    path: 'club',
    canActivate: [AuthGuard],
    component: ClubComponent
  },
  {
    path: '',
    canActivate: [UserLoggedInGuard],
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'register',
    canActivate: [UserLoggedInGuard],
    component: SignupComponent
  },
  {
    path: 'booking-detail',
    canActivate: [AuthGuard],
    component: BookingDetailComponent
  },
  {
    path: 'open-matches',
    canActivate: [AuthGuard],
    component: OpenMatchesListComponent
  },
  {
    path: 'view-profile',
    canActivate: [AuthGuard],
    component: ViewProfileComponent
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    component: AboutComponent
  },
  {
    path: 'message',
    canActivate: [AuthGuard],
    component: MessagingComponent
  },
  {
    path: 'player',
    canActivate: [AuthGuard],
    component: PlayersComponent
  },
  {
    path: 'edit',
    canActivate: [AuthGuard],
    component: EditProfileComponent
  },
  {
    path: 'join-match',
    canActivate: [AuthGuard],
    component: JoinMatchComponent
  },
  {
    path: 'create-match',
    canActivate: [AuthGuard],
    component: CreateMatchComponent
  },
  {
    path: 'sports',
    canActivate: [AuthGuard],
    component: SportListComponent
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
