import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, QueryList, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClubService } from 'src/app/services/club/club.service';
import { GetCourtService } from 'src/app/services/court/get-court.service';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

const moment = _moment;

@Component({
  selector: 'app-create-match',
  templateUrl: './create-match.component.html',
  styleUrls: ['./create-match.component.scss'],
})
export class CreateMatchComponent implements OnInit {
  @ViewChildren('checkBox') checkBox: QueryList<any>;
  clubs;
  clubDetailData;
  slotDateCurr;
  slotDetails;
  checked;
  checkedBoxes = 0;
  courtDetailData;
  isCOD: Number;
  matchData;
  temporaryPassedData;
  allSlots;
  submitDisabled = false;
  currentYear = moment().year();
  currentMonth = moment().month();
  currentDate = moment().date();
  todaydate = new Date();
  levelsParsed;
  sportLevel;
  isSportLoaded = false;
  isClubLoaded = false;
  Sports;
  country;
  country_id;
  sports_id;
  locationId;
  clubPost;
  countryLoad = false;
  clubLoad = false;
  courtLoad = false;
  levelLoad = false;
  slotLoad = false;

  myDateFilter = (m: Moment | null): boolean => {
    const year = (m || moment()).year();
    const month = (m || moment()).month();
    const date = (m || moment()).date();
    return year >= this.currentYear && date >= this.currentDate && month >= this.currentMonth;
  }

  constructor(
    public getCourts: GetCourtService,
    private getClubList: ClubService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.clubs= [];
    this.clubDetailData= [];
    this.slotDetails = {};
    this.checked = [];
    this.courtDetailData = [];
    this.matchData = {};
    this.levelsParsed = [];
    this.sportLevel = [];
  
    this.getCountry();
  }

  getCountry() {
    this.countryLoad = true;
    this.auth.getCountryList().subscribe(
      res => {
        this.country = res['message'];
        this.countryLoad = false;
        console.log(this.country);
      },
      err => {
        this.countryLoad = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        console.log('error country ', err);
      }
    );
  }

  onCountryChange(val) {
    this.isSportLoaded = true;
    this.locationId = val;
    this.auth.getSport(val).subscribe(
      res => {
        this.Sports = res['message'];
        this.isSportLoaded = false;
        console.log('this.Sports ', this.Sports);
      },
      err => {
        this.isSportLoaded = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        console.log('error country ', err);
      }
    );
  }

  getSport() {
    this.isSportLoaded = true;
    console.log('this.locationId', this.locationId);
    this.auth.getSport(this.locationId).subscribe(
      res => {
        this.Sports = res['message'];
        console.log('this.Sports', this.Sports);
        this.isSportLoaded = false;
        this.country_id = this.Sports[0].country_id;
        console.log('this.Sports.country_id', this.country_id);
      },
      err => {
        this.isSportLoaded = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        console.log('error country ', err);
      }
    );
  }

  onClubChange(val) {
    this.courtLoad = true;
    console.log('club value' + val);
    this.matchData['club_id'] = val;
    this.getClubList.getClub(val)
      .subscribe((singleClub: any) => {
        console.log('singleClub ', singleClub);
        this.clubDetailData = singleClub.message[0].courts;
        this.courtLoad = false;
      },
        error => {
          this.courtLoad = false;
          console.log('get error club', error)
        }
      );
  }

  onSportlevelChange(sportLevelVal) {
    console.log('sportLevelVal' + sportLevelVal);
    this.matchData['sport_level'] = sportLevelVal;
  }

  onPlayerChange(playerVal) {
    console.log('playerVal' + playerVal);
    this.matchData['total_player'] = playerVal;
    this.matchData['court_name'] = "BridgeTown";
  }

  onSportChange(value) {
    console.log('val-' + value);
    this.matchData['sport_id'] = value;
    this.sports_id = value;
    console.log('this.sports_id', this.sports_id);
    this.getClubs();
    this.getSportLevel()
  }

  getSportLevel() {
    this.levelLoad = true;
    this.auth.getSportLevel(this.sports_id).subscribe(
      res => {
        this.levelLoad = false;
        this.levelsParsed = JSON.parse(res.message[0].levels);
      },
      err => {
        this.levelLoad = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        console.log('sport level err.error.message ', err.error.message);
      }
    );
  }

  firstCheck = true;


  getCheckbox(check, int, j) {

    console.log('check, int, j', check, int, j);
    this.checked = [];
    const checked = this.checkBox.filter(checkbox => checkbox.checked);
    this.checkedBoxes = checked.length;
    console.log('this.checkedBoxes', this.checkedBoxes);

    let checkedLength = this.allSlots.filter(obj => obj.value).length
    console.log('checkedLength', checkedLength);

    if (this.checkedBoxes == 1 && this.firstCheck) {
      for (let index = 0; index < this.allSlots.length; index++) {
        this.allSlots[index].booking_status = 1;
        this.allSlots[index].value = false;
      }
      this.allSlots[j].booking_status = 0;
      this.allSlots[j + 1].booking_status = 0;
      this.allSlots[j + 2].booking_status = 0;
      this.allSlots[j + 3].booking_status = 0;
      this.firstCheck = false
    }






    checked.forEach(data => {
      this.checked.push(data.value)
    });

    if (check.checked) {
      if (this.checkedBoxes == 1 || this.checkedBoxes == 2) {
        if (this.allSlots[j + 1].booking_status == 0) {
          this.checkedBoxes = 2;
          this.allSlots[j + 1].value = true;
          this.checked.push(this.allSlots[j + 1].id)
        }
      }

      this.allSlots[j].value = true;
      // this.checked.push(this.allSlots[j].id);




    } else {

      this.allSlots[j].value = false;
      const index = this.allSlots.indexOf(this.allSlots[j].id);
      if (index > -1) {
        this.allSlots.splice(index, 1);
      }
      if (this.checkedBoxes == 0) {
        for (let index = 0; index < this.allSlots.length; index++) {
          this.allSlots[index].booking_status = 0;
          // this.allSlots.booking_slot[index].value = false;
        }
        this.firstCheck = true;
      }

    }
    console.log(this.checked);

    this.matchData['slot_id'] = this.checked;
    this.matchData['amount'] = this.courtDetailData[0]['amount'] * this.checkedBoxes;

  }

  onChange(value) {
    console.log('value', value);
    this.matchData['court_id'] = value;
    this.getCourts.getSingleCourt(value)
      .subscribe((singleCourt: any) => {
        console.log('singleCourt.message', singleCourt.message);
        this.courtDetailData = singleCourt.message;
      },
        err => {
          console.log('get error court', err)
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });

        }
      );
  }

  dateChanged($event) {
    this.slotLoad = true;
    this.slotDetails['book_date'] = formatDate($event.target.value, 'YYYY-MM-dd', 'en-US');
    this.matchData['book_date'] = this.slotDetails['book_date'];
    this.slotDetails['court_id'] = this.matchData['court_id'];
    this.getCourts.getSlots(this.slotDetails).subscribe(
      res => {
        this.slotLoad = false;
        this.checkedBoxes = 0;
        this.allSlots = res['Data'];  
      },
      err => {
        this.checkedBoxes = 0;
        console.log('update slots error', err);
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.slotLoad = false;
      }
    );
  }

  getClubs() {
    this.isClubLoaded = true;
    this.clubPost = {
      "country_id": this.locationId,
      "sports_id": this.sports_id
    }

    this.getClubList.getclubs(this.clubPost)
      .subscribe(
        res => {
          this.clubs = res.message;
          this.isClubLoaded = false;
        },
        err => {
          this.isClubLoaded = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );
  }


  radioChange(event: MatRadioChange) { this.isCOD = event.value; }

  createMatch() {
    this.submitDisabled = true;
    this.matchData['user_id'] = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    console.log('matchData', this.matchData);
    this.getCourts.createMatch(this.matchData).subscribe(
      res => {
        this.submitDisabled = false;
        this.matSnackBar.open('Correspondência criada com sucesso!!', 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.temporaryPassedData = res
        console.log('this.temporaryPassedData', this.temporaryPassedData);

        this.dialog.open(matchPayment, {
          data: {
            pageValue: this.temporaryPassedData,
            courtResponce: res,
          }
        });

      },
      err => {
        console.log(err);
        this.submitDisabled = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });

      }
    );

  }





}





@Component({
  selector: 'match-payment.html',
  templateUrl: 'match-payment.html',
})
export class matchPayment {

  modalData;
  couponButton = false;
  paymentButton = false;
  paymentReferenceNo = '';
  coupon_code;
  amount;
  paymentMethod = 0;
  paymentData;
  mobile;


  constructor(
    public dialogRef: MatDialogRef<matchPayment>,
    private getCourtServiceD: GetCourtService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.paymentData = {};
    this.modalData = this.data.pageValue.message;
    this.amount = this.modalData.amount
    this.paymentData['booking_id'] = this.modalData.id;
  }

  ngAfterViewInit() {
    // this.modalData = this.data.pageValue.message;
    // this.modalData['courtRes'] = this.data.courtResponce;
  }

  paymentGateway() {
    console.log('paymentGateway', this.amount);
    this.paymentData['amount'] = this.amount;
    this.paymentData['order_email'] = JSON.parse(localStorage.getItem('loggedInUserData'))._email;
    this.paymentData['mobile'] = JSON.parse(localStorage.getItem('loggedInUserData'))._mobile;
    this.paymentButton = true;
    if (this.paymentMethod == 0) {
      this.paymentData['flag'] = 'm';
      this.getCourtServiceD.makePayment(this.paymentData).subscribe(
        res => {
          this.matSnackBar.open(
            'O pagamento é realizado com sucesso. Por favor, note refence no. ' + res['message'][0].payment.reference_id,
            'Close',
            { duration: 50000 }
          );
          this.paymentButton = false;
          this.paymentReferenceNo = res['refrencenumber'];
          this.dialogRef.close();
          this.router.navigateByUrl('/match-detail?id=' + res['message'][0].id);
        },
        err => {
          this.paymentButton = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      )

    } else if (this.paymentMethod == 1) {

      this.getCourtServiceD.matchPaymentByGpo(this.paymentData).subscribe(
        res => {
          console.log('payment res', res);
          this.matSnackBar.open(
            'Please do the payment at merchant payment page',
            'Close',
            {
              duration: 50000, panelClass: ['mat-toolbar', 'mat-primary']
            }
          );
          this.paymentButton = false;
          window.location.href = 'https://reservatti.com/payment/payment.php?paymenturl='+res['paymentUrl'];
        },
        err => {
          this.paymentButton = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      )

    } 

  }

  discount() {
    this.couponButton = true;
    const discountData = {
      "amount": this.amount,
      "coupon_code": this.coupon_code,
      "booking_id": this.modalData.id
    }
    this.getCourtServiceD.verifyCoupon(discountData).subscribe(
      res => {
        this.couponButton = false;
        this.amount = res['amount']
        this.matSnackBar.open('Coupon Applied Successfully.', 'Close', {
          duration: 50000, panelClass: ['mat-toolbar', 'mat-primary']
        });
      },
      err => {
        this.couponButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )


  }

  radioChange(event) {
    if (event.value == 0) {
      this.paymentMethod = 0;
    }
    else if (event.value == 1) {
      this.paymentMethod = 1;
    }
    else if (event.value == 2) {
      this.paymentMethod = 2;
    } else {
      this.paymentMethod = 3;
    }

  }


}
