import { Component, OnInit } from '@angular/core';
import { PlayersService } from '../../services/players/players.service';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.scss'],
})
export class PlayersComponent implements OnInit {
  
  isplayersLoaded = true;
  allPlayerData;
  searchText = '';

  constructor(private playerData :PlayersService) { }

  ngOnInit() {
    this.allPlayerData=[];
    this.getPlayers();
  }

  getPlayers(){
    this.playerData.getAllPlayer()
    .subscribe(
      res=>{
        this.allPlayerData = res['message'];
        this.isplayersLoaded = false;
      },
      err=>{
        console.log('error getOpenMatches ' ,err);
        this.isplayersLoaded = false;
 
      }
    );
  }

}
