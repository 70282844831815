import { Component, OnInit, ViewEncapsulation, AfterViewInit, QueryList, ViewChildren, Inject, Optional } from '@angular/core';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import { ActivatedRoute } from '@angular/router';
import { GetCourtService } from './../../services/court/get-court.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import * as _moment from 'moment';
import { Moment } from 'moment';

const moment = _moment;

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-court-detail',
  templateUrl: './court-detail.component.html',
  styleUrls: ['./court-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class CourtDetailComponent implements OnInit {
  @ViewChildren('checkBox') checkBox: QueryList<any>;
  courtDetailData;
  slotDateCurr = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
  slotDetails;
  slotData;
  isDisabled = false;
  isSlotsLoaded = false;
  checked;
  checkedBoxes = 0;
  currentUserData;
  temporaryPassedData;
  isCOD: Number;
  currentYear = moment().year();
  currentMonth = moment().month();
  currentDate = moment().date();

  myDateFilter = (m: Moment | null): boolean => {
    const year = (m || moment()).year();
    const month = (m || moment()).month();
    const date = (m || moment()).date();
    return year >= this.currentYear && date >= this.currentDate && month >= this.currentMonth ;
  }

  constructor(
    private route: ActivatedRoute,
    private getCourtService: GetCourtService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.currentUserData = JSON.parse(localStorage.getItem('loggedInUserData'));
  }

  ngAfterViewInit() {
    this.getCourt();
  }

  dateChanged($event, courtId, i) {
    this.isSlotsLoaded = true
    this.slotDateCurr = formatDate($event.target.value, 'YYYY-MM-dd', 'en-US')
    this.slotDetails['book_date'] = this.slotDateCurr;
    this.slotDetails['court_id'] = courtId;
    console.log('slotDetails', this.slotDetails);

    this.getCourtService.getSlots(this.slotDetails).subscribe(
      res => {
        this.isSlotsLoaded = false;
        this.checkedBoxes = 0;
        this.courtDetailData[i]['timeslot'] = res['Data'];
      },
      err => {
        this.checkedBoxes = 0;
        this.isSlotsLoaded = false;
        console.log('update slots error', err);
      }
    );
  }
  getCourt() {
    const id = +this.route.snapshot.queryParamMap.get('id');
    this.getCourtService.getCourt(id)
      .subscribe((singleCourt: any) => {
        this.courtDetailData = singleCourt.message;
        singleCourt.message.forEach((singleCourtData) => {
          const imageParsed = JSON.parse(singleCourtData.image_location);
          singleCourtData['courtImageList'] = Object.values(imageParsed);
        });
      },
        error => {
          console.log('get error court' + error)
        }
      );

  }

  getCheckbox(ch , i) {
    this.checked = [];

    const checked = this.checkBox.filter(checkbox => checkbox.checked);
    this.checkedBoxes = checked.length;
    checked.forEach(data => {
      this.checked.push(data.value)
    })

  }

  getSlotFormData(data: any) {
    this.temporaryPassedData = data
    this.isDisabled = true;
    const checked = this.checkBox.filter(checkbox => checkbox.checked);
    this.getCourtService.bookCourtSlot(data).subscribe(
      res => {
        this.isDisabled = false;
        this.matSnackBar.open('A vaga foi reservada com sucesso !!', 'Close', { duration: 5000,panelClass: ['mat-toolbar', 'mat-primary']});
        checked.forEach(data => {
          data.checked = false
        });
        this.checkedBoxes = 0;
        // if (this.isCOD == 1) {
        //   this.dialog.open(PaymentModal, {
        //     data: {
        //       pageValue: this.temporaryPassedData,
        //       courtResponce: res,
        //     }
        //   });
        // }


      },
      err => {
        console.log(err);
        this.isDisabled = false;
        this.matSnackBar.open(err.error.message, 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary']});
        checked.forEach(data => {
          data.checked = false
        });
        this.checkedBoxes = 0;
      }
    );
  }

  radioChange(event: MatRadioChange) { this.isCOD = event.value; }

}



@Component({
  selector: 'payment-modal.html',
  templateUrl: 'payment-modal.html',
})
export class PaymentModal {

  modalData;
  paymentButton = false;
  paymentReferenceNo = '';

  constructor(
    public dialogRef: MatDialogRef<PaymentModal>,
    private getCourtServiceD: GetCourtService,
    private matSnackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

  }
  ngAfterViewInit() {
    this.modalData = this.data.pageValue;
    this.modalData['courtRes'] = this.data.courtResponce;
  }

  makePayment(paymentData) {
    paymentData['booking_id'] = this.modalData.courtRes.message.id;
    paymentData['amount'] = this.modalData.amount;
    paymentData['order_email'] = this.modalData.courtRes.message.user_email;
    console.log('this.modalData.courtRes.id ', this.modalData.courtRes.message.id, this.modalData.courtRes.message.user_email);
    this.paymentButton = true;


    this.getCourtServiceD.makePayment(paymentData).subscribe(
      res => {
        paymentData = '';
        this.matSnackBar.open(
          'O pagamento foi efetuado com sucesso. Observe a referência nº.' + res['refrencenumber'],
          'Close',
          { duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
        this.paymentButton = false;
        this.paymentReferenceNo = res['refrencenumber'];
        // this.dialogRef.close();
      },
      err => {
        console.log('payment error', err);
        paymentData = '';
        this.paymentButton = false;
        this.matSnackBar.open(err.error.message, 'Close', { duration: 5000,    
panelClass: ['mat-toolbar', 'mat-primary']});
      }
    )
  }


}
