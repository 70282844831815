export class User{
    constructor(
        public _id:string,
        public _profileImage:string,
        public _email:string,
        public _name:string,
        public _token:string,
        public _level:Number,
        public _mobile:Number
    ){}
}