import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {

  isDisabled = false;
  isSportLoaded = false;
  Sports;
  country;
  locationId: Number;
  sponsorLoading = true;
  sponsor;
  announcementLoading = true;
  announcement;
  upcomingBookLoading = true;
  upcomingBooking;
  upcomingMatchLoading = true;
  upcomingMatch;
  starRating = 0;
  selectedLocation: Number;
  @ViewChildren('chooseLocation') chslocation: ElementRef<any>;
  getUserID = {
    user_id: JSON.parse(localStorage.getItem('loggedInUserData'))._id
  }

  constructor(
    private auth: AuthService,
    private courtSer: GetCourtService,
    private router: Router,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.selectedLocation = JSON.parse(localStorage.getItem('selectedCountry'));
    this.getCountry();
    if (this.selectedLocation) {
      this.onCountryChange(this.selectedLocation);
    }
    this.route.params.subscribe(param => {
      this.getUpcomingBooking();
      this.getMatchs();
    });
  }

  ngAfterViewInit() {
    this.getSponsors();
    this.getAnnouncements();
  }

  getSponsors() {
    this.auth.getSponsorList().subscribe(
      res => {
        this.sponsorLoading = false;
        this.sponsor = res['message'];
      },
      err => {
        this.sponsorLoading = false;
      }
    );
  }

  getMatchs() {
    this.courtSer.getUpcomingMatchs().subscribe(
      (res) => {
        this.upcomingMatchLoading = false;
        this.upcomingMatch = res;
      },
      err => {
        this.upcomingMatchLoading = false;
      }
    );
  }

  getUpcomingBooking() {
    this.courtSer.getUpcomingBookings(this.getUserID).subscribe(
      res => {
        this.upcomingBookLoading = false;
        this.upcomingBooking = res['message'];
      },
      err => {
        this.upcomingBookLoading = false;
      }
    );



  }

  getAnnouncements() {
    this.auth.getAnnouncements().subscribe(
      res => {
        this.announcementLoading = false;
        this.announcement = res['message'];
      },
      err => {
        this.announcementLoading = false;
      }
    );
  }

  getCountry() {
    this.auth.getCountryList().subscribe(
      res => {
        this.country = res['message'];
      },
      err => {
      }
    );
  }

  onCountryChange(val) {
    localStorage.setItem('selectedCountry', JSON.stringify(val))
    this.selectedLocation = val
    this.isSportLoaded = true;
    this.locationId = val;
    this.auth.getSport(val).subscribe(
      res => {
        this.Sports = res['message'];
        this.isSportLoaded = false;
      },
      err => {
        this.isSportLoaded = false;
      }
    );

  }


}
