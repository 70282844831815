import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, SwiperOptions, Zoom } from 'swiper';
import {GetCourtService} from '../../services/court/get-court.service';


SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  courts;
  courtImageList;
  isCourtLoaded = true;
 
  constructor(public getCourtList: GetCourtService) { }

  ngOnInit() {
    this.getcourts();
  }

  getcourts(): void {
    this.isCourtLoaded = true;
    this.getCourtList.getCourts()
    .subscribe((data: any)  => {
      this.courts = data.message;
      data.message.forEach((dataMsg) => {
       const imageParsed = JSON.parse(dataMsg.image_location);
        dataMsg['courtImageList'] = Object.values(imageParsed);
      });
      this.isCourtLoaded = false;

    });
  }


}
