import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { PlayersService } from 'src/app/services/players/players.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss'],
})

export class MessagingComponent implements OnInit {
  showLeft= true;
  showList=false;
  isFriendsLoaded = true;
  allFriendsData;
  sendMessageButton = false;
  isChatLoaded = true;
  allChatData;
  messageData = '';
  sendMessArray;
  loggendInUserId = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
  playeChatHis;

  constructor(
    private playerData: PlayersService,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.sendMessArray={}
    this.router.events.subscribe(
      event => {
        clearInterval(this.playeChatHis);
      });

  }

  ngAfterViewInit() {
    this.getFriends();
  }

  getFriends() {
    this.playerData.getFriendsService()
      .subscribe(
        res => {
          this.allFriendsData = res['data'];
          this.isFriendsLoaded = false;
          if (!this.allFriendsData['class']) {
            this.allFriendsData['class'] = ''
          }
          this.allFriendsData[0]['class'] = "active";
          this.sendMessArray['friend'] = this.allFriendsData[0].id;

          this.playerData.getChatHistory(this.allFriendsData[0].id)
            .subscribe(
              res => {
                this.allChatData = res.data.reverse();
                this.isChatLoaded = false;
              },
              err => {
                this.isChatLoaded = false;
                this.matSnackBar.open(err.error.message, 'Close', {
                  duration: 5000,
                  panelClass: ['mat-toolbar', 'mat-primary']
                });
              }
            );

        },
        err => {
          this.isFriendsLoaded = false;
        }
      );
  }

  refreshChat(friednId) {
    this.playerData.getChatHistory(friednId)
      .subscribe(
        res => {
          this.allChatData = res.data.reverse();
          this.isChatLoaded = false;
        },
        err => {
          this.isChatLoaded = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );
  }

  setActive(i, playerId) {

    this.isChatLoaded = true;
    this.allChatData = [];
    this.sendMessArray['friend'] = playerId;
    this.playerData.getChatHistory(playerId)
      .subscribe(
        res => {
          this.allChatData = res.data.reverse();
          this.isChatLoaded = false;
          this.playeChatHis = setInterval(() => {
            this.playerData.getChatHistory(playerId)
              .subscribe(
                res => {
                  this.allChatData = res.data.reverse();
                  this.isChatLoaded = false;
                },
                err => {
                  this.isChatLoaded = false;
                  this.matSnackBar.open(err.error.message, 'Close', {
                    duration: 5000,
                    panelClass: ['mat-toolbar', 'mat-primary']
                  });
                }
              );
          }, 8000)

        },
        err => {
          this.isChatLoaded = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );

    clearInterval(this.playeChatHis);

    this.allFriendsData.forEach(element => {
      element.class = ''
    });
    this.allFriendsData[i]['class'] = "active";
  }

  sendMessage() {
    this.sendMessageButton = true;
    this.sendMessArray['chat'] = this.messageData;
    this.playerData.sendMessageService(this.sendMessArray)
      .subscribe(
        res => {
          this.messageData = '';
          this.sendMessageButton = false;
        },
        err => {
          this.sendMessageButton = false;
          this.messageData = '';
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );

  }

}
