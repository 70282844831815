import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SignupComponent } from './signup.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [SignupComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    HttpClientModule,
    IonicModule,
    MatSnackBarModule,
    FormsModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule
  ],
  bootstrap: [SignupComponent]
})
export class SignupModule { }
