import { Component, OnInit, AfterViewInit, Optional, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ClubService } from 'src/app/services/club/club.service';
import { GetCourtService } from 'src/app/services/court/get-court.service';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-club-list',
  templateUrl: './club-list.component.html',
  styleUrls: ['./club-list.component.scss'],
})
export class ClubListComponent implements OnInit {

  clubs;
  noClub = false;
  openMatchesList;
  isClubLoaded = true;
  isopenMatches = true;
  matchData;
  productLoading = true;
  product;
  config: SwiperOptions = {
    slidesPerView: 4,
    navigation: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      480: {
        slidesPerView: 1
      },
      640: {
        slidesPerView: 4
      }
    }

  };

  constructor(
    private getClubList: ClubService,
    private route: ActivatedRoute,
    private matSnackBar: MatSnackBar,
    private openMatches: GetCourtService,
    private auth: AuthService,
    public dialog: MatDialog

  ) { }


  ngOnInit() {
    this.route.params.subscribe(param => {
      const sportSelected = +this.route.snapshot.queryParamMap.get('sports_id');
      localStorage.setItem('sportSelected', JSON.stringify(sportSelected))
    });

    this.getClubs();
    this.getOpenMatches();
    this.getProducts();
  }

  getProducts() {
    this.auth.getEcommerceProducts().subscribe(
      res => {
        this.productLoading = false;
        this.product = res['message'];
      },
      err => {
        this.productLoading = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }

  getClubs(): void {
    this.isClubLoaded = true;
    const country_id = +this.route.snapshot.queryParamMap.get('country_id');
    const sports_id = +this.route.snapshot.queryParamMap.get('sports_id');
    const clubPost = {
      country_id: country_id,
      sports_id: sports_id
    }

    this.getClubList.getclubs(clubPost)
      .subscribe(
        res => {
          this.clubs = res.message;
          this.isClubLoaded = false;
          this.noClub = false;
        },
        err => {
          this.isClubLoaded = false;
          this.noClub = true;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );
  }

  getOpenMatches() {
    this.openMatches.getOpenMatches().subscribe(
      res => {
        this.openMatchesList = res['message'];
        this.isopenMatches = false;
        res['message'].forEach((dataMsg) => {
          const imageParsed = JSON.parse(dataMsg.court.image_location);
          dataMsg['courtImageList'] = Object.values(imageParsed);
        });

      },
      err => {
        this.isopenMatches = false;
      }
    );
  }


  confirmMatch(matches) {
    this.dialog.open(confirmMatch, {
      data: matches
    });
  }

}




@Component({
  selector: 'confirmMatch.html',
  templateUrl: 'confirmMatch.html',
})
export class confirmMatch {
  matchData ;
  joinButton = false;
  matchDataApi;

  constructor(
    public dialogRefrence: MatDialogRef<confirmMatch>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private getClubList: ClubService,
    private matSnackBar: MatSnackBar,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.matchDataApi={}
    this.matchData={}
    this.matchData = this.data;
  }

  joinMatch(matchId) {
    this.joinButton = true;
    this.matchDataApi['match_id'] = matchId;
    this.matchDataApi['user_id'] = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    this.matchDataApi['level'] = JSON.parse(localStorage.getItem('loggedInUserData'))._level;
    this.getClubList.joinMatch(this.matchDataApi)
      .subscribe(
        res => {
          this.joinButton = false;
          this.matSnackBar.open(res.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
          this.dialogRefrence.close(confirmMatch);
          this.router.navigateByUrl('/booking-history');
        },
        err => {
          this.joinButton = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );
  }

}