import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GetCourtService } from 'src/app/services/court/get-court.service';
import { share } from '../booking-detail/booking-detail.component';

@Component({
  selector: 'app-match-detail',
  templateUrl: './match-detail.component.html',
  styleUrls: ['./match-detail.component.scss'],
})
export class MatchDetailComponent implements OnInit {

  bookingId: Number;
  bookingData;
  bookingDataLoad = true;
  singleCourt;
  isDisabled = false;
  matchAmount;
  playerButton = false;
  paymentButton = false;
  userId = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
  currentPage = window.location.href;
  ShareByUrl = "Compartilhar por URL";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private getCourtService: GetCourtService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getMatch();
    console.log('userId',this.userId);
    
  }
  shareUrl() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (window.location.href));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.ShareByUrl = "Copied!!";
  }

  getMatch() {
    const id = +this.route.snapshot.queryParamMap.get('id');
    this.getCourtService.getMatchDetail(id)
      .subscribe(
        (upcomingDet) => {
          console.log(upcomingDet);
          this.bookingDataLoad = false;
          this.bookingData = upcomingDet['message'];
          this.matchAmount = this.bookingData[0]['amount'];
          upcomingDet['message'][0].court_details.forEach((singleCourtData) => {
            const imageParsed = JSON.parse(singleCourtData.image_location);
            this.singleCourt = Object.values(imageParsed);
          });
        },
        error => {
          this.bookingDataLoad = false;
          console.log('get error court', error)
        }
      );
  }

  removePlayer(playerId) {
    this.playerButton = true;
    console.log('getUpcomingDetail', playerId);
    this.getCourtService.removePlayerFromMatch(playerId)
      .subscribe((res) => {
        this.playerButton = false;
        console.log('res', res);
        this.getMatch();
        this.matSnackBar.open(
          res['message'],
          'Close',
          { duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
      },
        error => {
          this.playerButton = false;
          console.log('get error player remove', error)
        }
      );
  }

  copyMessage() {
    this.dialog.open(share, {
    });
  }

  cancelBooking() {
    this.isDisabled = true;
    const cancelParam = {
      'booking_id': this.bookingId
    }
    console.log('cancelBooking', this.bookingId);
    this.getCourtService.cancelBooking(cancelParam)
      .subscribe((upcomingDet) => {
        this.isDisabled = false;
        this.matSnackBar.open(upcomingDet['message'], 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
        console.log('upcomingDet', upcomingDet);
        setTimeout(() => {
          this.router.navigateByUrl('/location');
        }, 3000);
      },
        error => {
          this.matSnackBar.open(error.error.message, 'Close', { duration: 5000, panelClass: ['mat-toolbar', 'mat-primary'] });
          this.isDisabled = false;
          console.log('get error upcomingDet', error)
        }
      );
  }

  payNow() {
    this.paymentButton = true
    const payAgainData = {
      order_email: JSON.parse(localStorage.getItem('loggedInUserData'))._email,
      booking_id: this.route.snapshot.queryParamMap.get('id'),
      mobile: JSON.parse(localStorage.getItem('loggedInUserData'))._mobile,
      amount: this.matchAmount
    }

    this.getCourtService.matchPaymentByGpo(payAgainData).subscribe(
      res => {
        this.matSnackBar.open(
          'Por favor, faça o pagamento na página de pagamento do comerciante',
          'Close',
          {
            duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
        this.paymentButton = false;
        window.location.href = 'https://reservatti.com/payment/payment.php?paymenturl='+res['paymentUrl'];
        // this.dialogRef.close();
      },
      err => {
        console.log('payment error', err);
        // paymentData = '';
        this.paymentButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )

  }

}
