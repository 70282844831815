export const ENDPOINTS = {
    baseUrl: 'https://reservatti.com/admin/api/',
    userLogin: 'user-login',
    userRegister: 'user-register',
    courtsDetails: 'courts-details',
    courtList: 'court-list',
    courtBooking: 'court-booking',
    bookingDetails: 'booking-details',
    bookingHistory: 'user-booking-details',
    clubList: 'club',
    userProfile: 'user-profile',
    openMatches:'open-match',
    payment: 'payment',
    gpoPayment:'gpo-payment',
    allPlayer:'all-player',
    playerProfile:'player-profile',
    sentRequests:'sendRequests',
    friends:'friends',
    receivedRequest:'received-request',
    requestStatus:'requestStatus',
    clubNews:'club-news',
    updateUser:'update-user',
    contact:'contact',
    sportsList:'sports-list',
    countryList:'country-list',
    country:'country',
    sponsor:'sponsor',
    announcements:'announcements',
    upcomingBooking:'upcoming-bookings',
    sportLevel:'sport-level',
    joinMatch:'join-match',
    ecommerce:'ecommerce',
    courtsFilter:'courts-filter',
    cancelBooking:'cancel-booking',
    upcomingMatch:'mymatch',
    verifyCoupon:'verify-coupon',
    review:'review',
    shareBooking:'share-booking',
    chats:'chats',
    sendMessage:'sendMessage',
    forgotPassword:'forgot-password',
    gpoMatchPayment:'gpo-match-payment',
    tournaments:'tournaments',
    matchDetail:'sport-open-match',
    socialLogin:'social-login',
    removePlayer:'remove-player'   
}