import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetCourtService } from 'src/app/services/court/get-court.service';
import { confirmMatch } from '../club-list/club-list.component';

@Component({
  selector: 'app-join-match',
  templateUrl: './join-match.component.html',
  styleUrls: ['./join-match.component.scss'],
})
export class JoinMatchComponent implements OnInit {

 
  clubs;
  openMatchesList;
  isClubLoaded = true;
  isopenMatches = true;

  constructor(
    private openMatches: GetCourtService,
    public dialog: MatDialog

    ) { }

  ngOnInit() {
    this.getOpenMatches();
  }
  confirmMatch(matches){    
    this.dialog.open(confirmMatch, {
      data: matches
    });
  }
  getOpenMatches(){
    this.openMatches.getOpenMatches().subscribe(
      res=>{
        this.isopenMatches = false;
        this.openMatchesList = res['message'];
        res['message'].forEach((dataMsg) => {
        console.log('dataMsg' , dataMsg);
        const imageParsed = JSON.parse(dataMsg.court.image_location);
          dataMsg['courtImageList'] = Object.values(imageParsed);
          console.log(dataMsg['courtImageList']);
    
        });
    
      },
      err=>{
        console.log('error getOpenMatches ' ,err);
        this.isopenMatches = false;        
      }
    );
  }

}
