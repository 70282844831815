import { Component, AfterViewInit, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { GetCourtService } from 'src/app/services/court/get-court.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-booking-history',
  templateUrl: './booking-history.component.html',
  styleUrls: ['./booking-history.component.scss'],
})
export class BookingHistoryComponent implements OnInit {

  bookingHistoryData;
  isCourtLoaded = true;
  noBooking = false;
  upcomingMatchLoading = true;
  upcomingMatch;

  constructor(
    private user: UserService,
    private courtSer: GetCourtService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.bookingHistoryData=[]
    // this.upcomingMatch=[]
    this.route.params.subscribe(param => {
      this.getBookings();
      this.getMatchs();
    });
  }


  getMatchs() {
    this.courtSer.getUpcomingMatchs().subscribe(
      (res: any) => {
        this.upcomingMatchLoading = false;
        this.upcomingMatch = res;
      },
      err => {
        this.upcomingMatchLoading = false;
        this.upcomingMatch = err.error.message;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }

  getBookings() {
    this.user.getBookingHistory().subscribe(
      (res: any) => {
        this.isCourtLoaded = false;
        this.bookingHistoryData = res.message;
        if (this.bookingHistoryData == 'There is no booking history !') {
          this.noBooking = true;
        } else {
          this.noBooking = false;
        }
      },
      err => {
        this.isCourtLoaded = false;
        if (err.message.message == 'There is no booking history !') {
          this.noBooking = true;
        } else {
          this.noBooking = false;
        }
      }
    );

  }

}
