import { Component, OnInit } from '@angular/core';
import { PlayersService } from 'src/app/services/players/players.service';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss'],
})
export class FriendsComponent implements OnInit {
  
  isFriendsLoaded = true;
  allPlayerData;
  constructor(private playerData :PlayersService) { }

  ngOnInit() {
    this.allPlayerData=[];
    this.getFriends()  
  }
  
  ngAfterViewInit() {
    this.getFriends();
  }

  getFriends(){
    this.playerData.getFriendsService()
    .subscribe(
      res=>{
        this.allPlayerData = res['data'];
        this.isFriendsLoaded = false;
      },
      err=>{
        this.isFriendsLoaded = false; 
      }
    );
  }
}
