import { Component, OnInit, DoCheck } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.scss'],
})
export class ViewProfileComponent implements OnInit {

  profileData;
  isProfileDataLoaded=true;

  constructor(
    private getuserProfile: UserService,
    route:ActivatedRoute
  ) { 
      this.isProfileDataLoaded= true;
      route.params.subscribe(val => {
        this.getUser();
      });

    }

  ngOnInit() {
    this.getUser();
    this.profileData={}
  }

  getUser(){
    this.isProfileDataLoaded = true;
    this.getuserProfile.getUserProfile()
    .subscribe((data: any)  => {      
      this.profileData = data.data[0];
      console.log('profile data', this.profileData);
      this.isProfileDataLoaded= false;
    });


  }


}
