import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  forgotData;
  isDisabled = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() { 
    this.forgotData = {};
  }

  loginUser() {
    this.isDisabled = true;
    console.log("this.forgotData", this.forgotData);
    this.auth.forgotUser(this.forgotData).subscribe(
      res => {
        console.log('logged res ', res);
        this.isDisabled = false;
        this.matSnackBar.open(res['message'], 'Close', { 
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
         });
        this.router.navigateByUrl('/');
      },
      (err: any) => {
        console.log('err', err);
        this.isDisabled = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });

      }
    );
  }

}
