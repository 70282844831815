import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sport-list',
  templateUrl: './sport-list.component.html',
  styleUrls: ['./sport-list.component.scss'],
})
export class SportListComponent implements OnInit {

  isSportLoaded = true;
  Sports;
  country;
  constructor(
      private auth: AuthService,
      private route: ActivatedRoute,
      private matSnackBar: MatSnackBar
    ) { }

  ngOnInit() {}
  
  ngAfterViewInit() {
    this.getSport();
  }

  getSport(){
    const val = +this.route.snapshot.queryParamMap.get('id');
    console.log('get sport'+val);

    this.auth.getSport(val).subscribe(
      res=>{
        this.Sports = res['message'];
        console.log('this.Sports',this.Sports);
        this.isSportLoaded=false;
      },
      err=>{
        this.isSportLoaded=false;
        this.matSnackBar.open(err.error.message, 'Close', { duration: 5000,    
panelClass: ['mat-toolbar', 'mat-primary']});
        console.log('error country ' ,err);
      }
    );

  }

}
