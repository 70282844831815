import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ClubService } from 'src/app/services/club/club.service';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss'],
})
export class ClubComponent implements OnInit {
  @ViewChildren('checkBox') checkBox: QueryList<any>;
  reviewDisable = false;
  clubDetailData;
  isClubLoaded = true;
  singleClubD;
  bookCourtData;
  slotDateCurr = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
  slotDetails;
  slotData;
  isDisabled = false;
  isSlotsLoaded = false;
  checked;
  checkedBoxes = 0;
  temporaryPassedData;
  allCourts;
  allreviews;
  reviewData;
  allSlots;
  courtBookingData = {
    booking_date: formatDate(new Date(), 'YYYY-MM-dd', 'en-US')
  };
  reviewComment = '';
  rating = 0;

  constructor(
    private route: ActivatedRoute,
    private getClubService: ClubService,
    private getCourtService: GetCourtService,
    private matSnackBar: MatSnackBar,
    public dialog: MatDialog

  ) {
    this.getClubService.courtInClub.subscribe(courtsList => {
      this.allCourts = courtsList;
    });
  }

  ngOnInit() {
    this.clubDetailData = [];
    this.bookCourtData = {};
    this.slotDetails = {};
    this.slotData = {};
    this.checked = [];
    this.allCourts = [];
    this.allreviews = [];
    this.reviewData = {};
  

   }

  ngAfterViewInit() {
    this.getClub();
    this.getReview();
  }

  somethingChanged(rating) {
    this.reviewData['review'] = rating;
  }

  addReview() {
    this.reviewDisable = true;
    this.reviewData['club_id'] = +this.route.snapshot.queryParamMap.get('id');;
    this.reviewData['user_id'] = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    this.reviewData['comments'] = this.reviewComment;
    
    this.getCourtService.addReview(this.reviewData).subscribe(
      res => {
        this.reviewDisable = false;
        this.matSnackBar.open(res['message'], 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.reviewComment = '';
        this.rating = 0;
        this.getClub();
        this.getReview()
      },
      err => {
        this.reviewDisable = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.reviewComment = '';
        this.rating = 0;
      }
    );
  }

  getReview() {
    const reviewId = +this.route.snapshot.queryParamMap.get('id');
    this.getClubService.getReview(reviewId)
      .subscribe((singlereview: any) => {
        this.allreviews = singlereview.message;
      },
        error => {
        }
      );

  }

  getClub() {
    const id = +this.route.snapshot.queryParamMap.get('id');
    this.getClubService.getClub(id)
      .subscribe((singleClub: any) => {
        this.clubDetailData = singleClub.message;
        this.allSlots = singleClub.message[0].courts[0].booking_slot;
        this.getClubService.courtInClub.next(singleClub.message[0].courts);
        singleClub.message[0].courts.forEach((singleCourtData) => {
          const imageParsed = JSON.parse(singleCourtData.image_location);
          singleCourtData['courtImageList'] = Object.values(imageParsed);
          this.singleClubD = singleCourtData
        });
        this.isClubLoaded = false;
      },
        error => {
          this.isClubLoaded = false;
          this.matSnackBar.open(error.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );

  }

  getCheckbox(check, int, j) {

    const checked = this.checkBox.filter(checkbox => checkbox.checked);
    this.checkedBoxes = checked.length;
    let checkedLength = this.allCourts[int].booking_slot.filter(obj => obj.value).length

    if (checkedLength == 0) {
      for (let index = 0; index < this.allCourts[int].booking_slot.length; index++) {
        this.allCourts[int].booking_slot[index].booking_status = 1;
        this.allCourts[int].booking_slot[index].value = false;
      }
      this.allCourts[int].booking_slot[j].booking_status = 0;
      this.allCourts[int].booking_slot[j + 1].booking_status = 0;
      this.allCourts[int].booking_slot[j + 2].booking_status = 0;
      this.allCourts[int].booking_slot[j + 3].booking_status = 0;
    }


    if (check.checked) {
      if (!this.allCourts[int]['slot_id']) { this.allCourts[int]['slot_id'] = []; }
      if (checkedLength == 1 || checkedLength == 0) {
        if (this.allCourts[int].booking_slot[j + 1].booking_status == 0) {
          this.checkedBoxes = 2;
          this.allCourts[int].booking_slot[j + 1].value = true;
          this.allCourts[int]['slot_id'].push(this.allCourts[int].booking_slot[j + 1].id)
        }
      }
      this.allCourts[int].booking_slot[j].value = true;
      this.allCourts[int]['slot_id'].push(this.allCourts[int].booking_slot[j].id);
    } else {
      this.allCourts[int].booking_slot[j].value = false;
      const index = this.allCourts[int]['slot_id'].indexOf(this.allCourts[int].booking_slot[j].id);
      if (index > -1) {
        this.allCourts[int]['slot_id'].splice(index, 1);
      }
      if (checkedLength == 1) {
        for (let index = 0; index < this.allCourts[int].booking_slot.length; index++) {
          this.allCourts[int].booking_slot[index].booking_status = 0;
          // this.allCourts[int].booking_slot[index].value = false;
        }
      }
    }
  }

  bookCourt(disableButton, i) {
    // this.isDisabled[i]=true;
    disableButton._disabled = true;
    this.courtBookingData['club_id'] = this.allCourts[i]['club_id'];
    this.courtBookingData['court_id'] = this.allCourts[i]['id'];
    this.courtBookingData['booking_court_name'] = this.allCourts[i]['court_name'];
    this.courtBookingData['sports_id'] = this.allCourts[i]['sports_id'];
    this.courtBookingData['user_id'] = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    this.courtBookingData['amount'] = this.allCourts[i]['amount'] * this.allCourts[i]['slot_id'].length;
    this.courtBookingData['slot_id'] = this.allCourts[i]['slot_id'];

    this.getCourtService.bookCourtSlot(this.courtBookingData).subscribe(
      res => {
        this.isDisabled = false;
        this.matSnackBar.open('Slot has been booked successfully !!', 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
        this.temporaryPassedData = res
        this.dialog.open(payByRefrence, {
          width: '80%',
          data: {
            pageValue: this.temporaryPassedData,
            courtResponce: res,
          }
        });
      },
      err => {
        this.isDisabled = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );

    this.getClub();
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(filterModal, {
      disableClose: true,
      data: {
        allSlots: this.allSlots,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.courtBookingData['booking_date'] = result.filterDateData;
      if (result.filterDateData == undefined || result.filterDateData == null || result.filterDateData == '') {
        this.courtBookingData['booking_date']= formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
      }
    });


  }

}






@Component({
  selector: 'payByRefrence.html',
  templateUrl: 'payByRefrence.html',
  styleUrls: ['./club.component.scss'],
})
export class payByRefrence {

  modalData;
  couponButton = false;
  paymentButton = false;
  paymentReferenceNo = '';
  coupon_code;
  amount;
  paymentMethod = 0;
  paymentData;
  mobile;
  totalTime

  constructor(
    public dialogRef: MatDialogRef<payByRefrence>,
    private getCourtServiceD: GetCourtService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.paymentData = {};
    this.modalData = this.data.pageValue.message;
    this.totalTime = JSON.parse(this.modalData.slot_id).length * 30;
    // this.amount = this.modalData.amount * JSON.parse(this.modalData.slot_id).length;
    this.amount = this.modalData.amount
    this.paymentData['booking_id'] = this.modalData.id;
  }

  ngAfterViewInit() {
    this.modalData = this.data.pageValue.message;
    // this.modalData['courtRes'] = this.data.courtResponce;
  }

  paymentGateway() {
    this.paymentData['amount'] = this.amount;
    this.paymentData['order_email'] = JSON.parse(localStorage.getItem('loggedInUserData'))._email;
    this.paymentData['mobile'] = JSON.parse(localStorage.getItem('loggedInUserData'))._mobile;
    this.paymentButton = true;
    if (this.paymentMethod == 0) {
      this.getCourtServiceD.makePayment(this.paymentData).subscribe(
        res => {
          this.matSnackBar.open(
            'O pagamento é realizado com sucesso. Por favor, note refence no. ' + res['message'][0].payment.reference_id,
            'Close',
            { duration: 50000, panelClass: ['mat-toolbar', 'mat-primary']
          }
          );
          this.paymentButton = false;
          this.paymentReferenceNo = res['refrencenumber'];
          this.dialogRef.close();
          this.router.navigateByUrl('/booking-detail?id=' + res['message'][0].id);
        },
        err => {
          this.paymentButton = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      )

    } else if (this.paymentMethod == 1) {

      this.getCourtServiceD.paymentByGpo(this.paymentData).subscribe(
        res => {
          this.matSnackBar.open(
            'Please do the payment at merchant payment page',
            'Close',
            { duration: 50000,
              panelClass: ['mat-toolbar', 'mat-primary']
          }
          );
          this.paymentButton = false;
          window.location.href = 'https://reservatti.com/payment/payment.php?paymenturl='+res['paymentUrl'];
        },
        err => {
          this.paymentButton = false;
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      )

    } 

  }

  discount() {
    this.couponButton = true;
    const discountData = {
      "amount": this.amount,
      "coupon_code": this.coupon_code,
      "booking_id": this.modalData.id
    }
    this.getCourtServiceD.verifyCoupon(discountData).subscribe(
      res => {
        this.couponButton = false;
        this.amount = res['amount']
        this.matSnackBar.open('Coupon Applied Successfully.', 'Close', { duration: 50000,            panelClass: ['mat-toolbar', 'mat-primary']
      });
      },
      err => {
        this.couponButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )


  }

  radioChange(event) {
    if (event.value == 0) {
      this.paymentMethod = 0;
    }
    else if (event.value == 1) {
      this.paymentMethod = 1;
    }
    else if (event.value == 2) {
      this.paymentMethod = 2;
    } else {
      this.paymentMethod = 3;
    }

  }


}



@Component({
  selector: 'filterModal.html',
  templateUrl: 'filterModal.html',
})
export class filterModal {

  todaydate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
  maxDate = new Date();
  slotDateCurr = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
  Sports;
  todaySlot;
  filterCourtData = {
    date: formatDate(new Date(), 'YYYY-MM-dd', 'en-US')
  };
  staticSlot = [
    "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00"
  ]
  filterSlots ;
  @ViewChild(ClubComponent) child: ClubComponent;

  constructor(
    public dialogRefrence: MatDialogRef<filterModal>,
    private auth: AuthService,
    private matSnackBar: MatSnackBar,
    private route: ActivatedRoute,
    private getFilterClub: ClubService,
    @Inject(MAT_DIALOG_DATA) public filterDateData: string,
  ) {
    this.maxDate.setDate(this.maxDate.getDate() + 15);
  }

  ngOnInit() {
    this.todaySlot=[];
    this.Sports= [];
    this.filterSlots = []


    this.filterDateData['allSlots'].forEach(currentSlot => {
      this.todaySlot.push(currentSlot.start_time.split(' ')[0])
    });
    this.filterSlots = this.todaySlot;
    this.filterCourtData['sports_id'] = JSON.parse(localStorage.getItem('sportSelected'));
    this.filterCourtData['date'] = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');    
    const sportVal = JSON.parse(localStorage.getItem('selectedCountry'));
    this.auth.getSport(sportVal).subscribe(
      res => {
        this.Sports = res['message'];
      },
      err => {
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );

  }


  onTimeChange(timeSlot) {
    this.filterCourtData['time'] = timeSlot;

  }


  dateChanged($event) {
    this.filterCourtData['date'] = formatDate($event.target.value, 'YYYY-MM-dd', 'en-US')
    if (formatDate($event.target.value, 'YYYY-MM-dd', 'en-US') == this.todaydate) {
      this.filterSlots = this.todaySlot;
    } else {
      this.filterSlots = this.staticSlot;
    }
  }

  filterCourt() {
    this.filterCourtData['club_id'] = this.route.snapshot.queryParamMap['params'].id;    
    this.getFilterClub.filterCourt(this.filterCourtData)
      .subscribe(
        res => {
          this.matSnackBar.open('success', 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });

          res.message.forEach((singleCourtData) => {
            const imageParsed = JSON.parse(singleCourtData.image_location);
            singleCourtData['courtImageList'] = Object.values(imageParsed);
          });
          this.getFilterClub.courtInClub.next(res.message);
          this.dialogRefrence.close({
            filterDateData: this.filterCourtData['date']
          });
        },
        err => {
          this.matSnackBar.open(err.error.message, 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        }
      );
  }


}