import { Component, OnInit } from '@angular/core';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  latestNews;
  isNewsLoaded= true;

  constructor(
    private courtService: GetCourtService,
    ) { }

  ngOnInit() {
    this.getLatestNews();
  }

  getLatestNews(){
    this.courtService.getClubNews().subscribe(
      res=>{
        this.latestNews = res['message'];
        console.log(this.latestNews);
        this.isNewsLoaded= false;
      },
      err=>{
        console.log('error getOpenMatches ' ,err);
        this.isNewsLoaded= false;
      }
    );
  }

}
