import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'playerFilter'
})
export class PlayerFilterPipe implements PipeTransform {

  transform(playerList: Array<any>, search: String): any {
    if(playerList && search)
      return playerList.filter((data)=> data.first_name.toLowerCase().indexOf(search.toLowerCase()) > -1)
    return playerList;
  }

}
