import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { PlayersService } from 'src/app/services/players/players.service';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.scss'],
})
export class PlayerDetailComponent implements OnInit {

  singlePlayerData;
  isProfileDataLoaded = true;
  sendRequesttButton = false;
  requestData;
  constructor(
    private route: ActivatedRoute,
    private getPlayersService: PlayersService,
    private matSnackBar: MatSnackBar,
  ) { }

  ngOnInit() { 
    this.singlePlayerData={}
    this.requestData={}
    this.route.params.subscribe(param => {
      this.getplayer();
    });
  }

  getplayer() {
    const playerID = +this.route.snapshot.queryParamMap.get('id');
    this.getPlayersService.getSinglePlayer(playerID)
      .subscribe((singlePlayer: any) => {
        this.singlePlayerData = singlePlayer.message[0];
        this.isProfileDataLoaded = false;
      },
        error => {
          this.isProfileDataLoaded = false;
          console.log('get error court' + error)
        }
      );

  }


  sendReq() {
    const send_id = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
    this.requestData['sender_id'] = send_id;
    const recieverID = +this.route.snapshot.queryParamMap.get('id');
    this.requestData['receiver_id'] = recieverID;
    this.sendRequesttButton = true;
    this.getPlayersService.sendRequestService(this.requestData).subscribe(
      res => {
        this.requestData['receiver_id'] = '';
        this.matSnackBar.open(
          'Solicitação enviada com sucesso!!',
          'Close',
          {
            duration: 50000,
            panelClass: ['mat-toolbar', 'mat-primary']
          }
        );
        this.sendRequesttButton = false;

      },
      err => {
        console.log(err);
        this.requestData['receiver_id'] = '';
        this.sendRequesttButton = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    )
    this.getplayer();
  }

}
