import { SocialAuthService } from 'angularx-social-login';
import { Component, OnInit, DoCheck } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import Pusher from 'pusher-js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  loggedInUser = '';
  loggedInUserImage = '';
  showBack = false;
  public pusherClient: Pusher;
  userId;

  constructor(
    private auth: AuthService,
    private socialAuth: SocialAuthService,
    private _location: Location,
    private router: Router
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        const spliturl = event['url'].split('?')[0];
        if (spliturl == '/booking-detail' || spliturl == '/match-detail') {
          this.showBack = true;
        } else {
          this.showBack = false;
        }
      }
    });
  }

  ngOnInit() {    
    this.pusherClient = new Pusher('e5b14a0aa42111ad5571', { cluster: 'ap1' });
    const channel = this.pusherClient.subscribe('user');
    channel.bind(
      'friend-event',
      function (data) {
        if (data.user.notificatioForAll) {
          Notification.requestPermission().then(prem => {
            if (prem === "granted") {
              const notification = new Notification("Reservatti Notification", {
                body: data.user.event_message,
                data: data.user.first_name,
                icon: "/assets/icon/circleLogo.jpg"
              })
              notification.addEventListener("error", e => {
                console.log("notification error", e);
              })
            }
          })
        }
        else{
          this.userId = JSON.parse(localStorage.getItem('loggedInUserData'))._id;
          if (this.userId == data.user.requested_id) {
            Notification.requestPermission().then(prem => {
              if (prem === "granted") {
                const notification = new Notification("Reservatti Notification", {
                  body: data.user.event_message,
                  data: data.user.first_name,
                  icon: "/assets/icon/circleLogo.jpg"
                })
                notification.addEventListener("error", e => {
                  console.log("notification error", e);
                })
              }
            })
  
          }
        }
      }
    );
    this.loggedInUser = this.auth.loggedInUserName;
    this.loggedInUserImage = this.auth.loggedInUserImageUrl;
  }


  signOut() {
    this.auth.signOut();
    // this.socialAuth.signOut();

  }
  backClicked() {
    this._location.back();
  }


}
