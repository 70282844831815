import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/endpoint';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private getBookingHistoryUrl = ENDPOINTS.baseUrl + ENDPOINTS.bookingHistory;
  private userProfileUrl = ENDPOINTS.baseUrl + ENDPOINTS.userProfile;
  private updateUsereUrl = ENDPOINTS.baseUrl + ENDPOINTS.updateUser;


  header = new HttpHeaders({
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('loggedInUserData'))._token
  });
  currentUser = {
    'user_id': JSON.parse(localStorage.getItem('loggedInUserData'))._id
  }

  constructor(private http: HttpClient) { }

  getBookingHistory() {
    return this.http.post(this.getBookingHistoryUrl, this.currentUser, {
      headers: this.header
    })
  }


  updateUser(updateUsereData) {
    return this.http.post(this.updateUsereUrl, updateUsereData, {
      headers: this.header
    })
  }

  getUserProfile(): Observable<any> {
    return this.http.get<any>(this.userProfileUrl, {
      headers: this.header
    })
    .pipe(
      tap(_ => console.log('fetched courts')),
      catchError(this.handleError<any[]>('getCourts', []))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


}
