import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  hide = true;
  profileData;
  isProfileDataLoaded = true;
  registerUserData;
  isDisabled = false;
  localUrl
  levelsParsed;
  userDate;
  isSportLoaded = true;
  Sports;
  country_id;
  country;
  locationId: Number;

  constructor(
    private getuserProfile: UserService,
    private matSnackBar: MatSnackBar,
    private auth: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.profileData={}
    
    this.route.params.subscribe(param => {
      this.getUser();
      this.getSport();
      this.getCountry()
    });
  }

  dateChanged($event) {
    this.userDate = formatDate($event.target.value, 'YYYY-MM-dd', 'en-US')
    this.profileData['dob'] = this.userDate;
  }

  getUser() {
    this.getuserProfile.getUserProfile()
      .subscribe((data: any) => {
        this.profileData = data.data[0];
        this.userDate = this.profileData['dob'];
        this.isProfileDataLoaded = false;
        this.getSportLevel()
      });
  }

  getSport() {
    const val = JSON.parse(localStorage.getItem('selectedCountry'));
    this.auth.getSport(val).subscribe(
      res => {
        this.Sports = res['message'];
        this.isSportLoaded = false;
        this.country_id = this.Sports[0].country_id;
      },
      err => {
        this.isSportLoaded = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );

  }

  getCountry() {
    this.auth.getCountryList().subscribe(
      res => {
        this.country = res['message'];
      },
      err => {
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }

  onCountryChange(val) {
    this.locationId = val;
    this.profileData['country'] = val;
  }

  onGenderChange(genderVal) {
    this.profileData['gender'] = genderVal;
  }

  onSportChange(value) {
    this.profileData['sports'] = value;
    this.getSportLevel();
  }
  getSportLevel() {
    this.auth.getSportLevel(this.profileData['sports']).subscribe(
      res => {
        this.levelsParsed = JSON.parse(res.message[0].levels);
      },
      err => {
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }

  onSportlevelChange(sportLevelVal) {
    this.profileData['level'] = sportLevelVal;
  }

  handleUpload(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.localUrl = event.target.result;
        try {
          this.profileData['profile_pic'] = this.localUrl;
        }
        catch (e) {
          console.log(e);
        }
      }
      reader.readAsDataURL(event.target.files[0]);
    }

  }

  registerUser() {
    this.isDisabled = true;
    this.getuserProfile.updateUser(this.profileData).subscribe(
      (res: any) => {
        this.isDisabled = false;
        this.matSnackBar.open('Usuário atualizado com sucesso!!', 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      },
      (err: any) => {
        this.isDisabled = false;
        this.matSnackBar.open(err.error.message, 'Close', {
          duration: 5000,
          panelClass: ['mat-toolbar', 'mat-primary']
        });
      }
    );
  }



}
