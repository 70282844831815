import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/endpoint';

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  private getClubsUrl = ENDPOINTS.baseUrl + ENDPOINTS.clubList;
  private joinMatchUrl = ENDPOINTS.baseUrl + ENDPOINTS.joinMatch;
  private courtFIlterUrl = ENDPOINTS.baseUrl + ENDPOINTS.courtsFilter;
  private singleClubUrl = 'https://reservatti.com/admin/api/club';
  private getReviewUrl = 'https://reservatti.com/admin/api/get-review';

  courtInClub = new Subject<any>();

  header = new HttpHeaders({
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('loggedInUserData'))._token
  });

  constructor(private http: HttpClient) { }

  getclubs(getClubData): Observable<any> {
    return this.http.post(this.getClubsUrl, getClubData)
  }
  
  joinMatch(matchData): Observable<any> {
    return this.http.post(this.joinMatchUrl, matchData,{
        headers: this.header
      });
  }

  filterCourt(courtData): Observable<any> {
    return this.http.post(this.courtFIlterUrl, courtData,{
        headers: this.header
      });
  }

  getClub(id: number): Observable<any> {
    const url = `${this.singleClubUrl}/?id=${id}`;
    return this.http.get<any>(url, {
      headers: this.header
    }).pipe(
      tap(_ => console.log(`fetched single club id=${id}`)),
      catchError(this.handleError<any>(`get club error id=${id}`))
    );
  }
  getReview(rId: number): Observable<any> {
    const revUrl = `${this.getReviewUrl}/?club_id=${rId}`;
    return this.http.get<any>(revUrl, {
      headers: this.header
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }


}
