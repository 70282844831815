import { Component, OnInit } from '@angular/core';
import { PlayersService } from 'src/app/services/players/players.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
})
export class RankingComponent implements OnInit {

  isplayersLoaded = true;
  allPlayerData:[]
  constructor(private playerData :PlayersService) { }

  ngOnInit() {
    this.getPlayers()  
  }

  getPlayers(){
    this.playerData.getAllPlayer()
    .subscribe(
      res=>{
        this.allPlayerData = res['message'];
        console.log(this.allPlayerData);
        this.isplayersLoaded = false;

      },
      err=>{
        console.log('error getOpenMatches ' ,err);
        this.isplayersLoaded = false;
 
      }
    );
  }

}
