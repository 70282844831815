import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GetCourtService } from 'src/app/services/court/get-court.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {


  contactUserData;
  isDisabled = false;

  constructor(
    private contactService: GetCourtService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.contactUserData={};
  }

  contactUser(){
    this.isDisabled = true;
    this.contactService.contact(this.contactUserData).subscribe(
        (res)=>{
          this.isDisabled = false;
          this.contactUserData={};
          this.matSnackBar.open('Formulário enviado com sucesso!!', 'Close', {
            duration: 5000,
            panelClass: ['mat-toolbar', 'mat-primary']
          });
        },
        (err:any)=>{
          this.isDisabled = false;
          this.matSnackBar.open(err.error.message, 'Close', {duration: 5000});

          // err.error.message.forEach(el=>{
          //   console.log('el',el);
          //   this.matSnackBar.open(el, 'Close', {duration: 5000});
          // })

        }
      );
  }

}
