import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/endpoint';

@Injectable({
  providedIn: 'root'
})

export class GetCourtService {

  private getCourtUrl = ENDPOINTS.baseUrl + ENDPOINTS.courtsDetails;
  private getSingleCourtUrl = 'https://reservatti.com/admin/api/court-list';
  private createMatchUrl = 'https://reservatti.com/admin/api/create-match';
  private slotBookUrl = ENDPOINTS.baseUrl + ENDPOINTS.courtBooking;
  private getSlotsUrl = ENDPOINTS.baseUrl + ENDPOINTS.bookingDetails;
  private openMatchesUrl = ENDPOINTS.baseUrl + ENDPOINTS.openMatches;
  private paymentUrl = ENDPOINTS.baseUrl + ENDPOINTS.payment;
  private clubNewsUrl = ENDPOINTS.baseUrl + ENDPOINTS.clubNews;
  private contactUrl = ENDPOINTS.baseUrl + ENDPOINTS.contact;
  private upcomingBookingUrl = ENDPOINTS.baseUrl+ENDPOINTS.upcomingBooking;
  private bookingDetailUrl = ENDPOINTS.baseUrl+ENDPOINTS.bookingHistory;
  private cancelBookingUrl = ENDPOINTS.baseUrl+ENDPOINTS.cancelBooking;
  private upcomingMatchUrl = ENDPOINTS.baseUrl+ENDPOINTS.upcomingMatch;
  private verifyCouponUrl = ENDPOINTS.baseUrl+ENDPOINTS.verifyCoupon;
  private gpoPaymnetUrl = ENDPOINTS.baseUrl+ENDPOINTS.gpoPayment;
  private reviewUrl = ENDPOINTS.baseUrl+ENDPOINTS.review;
  private shareUrl = ENDPOINTS.baseUrl+ENDPOINTS.shareBooking;
  private  gpoMatchPaymentUrl = ENDPOINTS.baseUrl+ENDPOINTS.gpoMatchPayment;
  private tournamentUrl = ENDPOINTS.baseUrl+ENDPOINTS.tournaments;
  private matchDetailUrl = ENDPOINTS.baseUrl+ENDPOINTS.matchDetail;
  private removePlayer = ENDPOINTS.baseUrl+ENDPOINTS.removePlayer;

 
  header = new HttpHeaders({
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('loggedInUserData'))._token
  });

  constructor(private http: HttpClient) { }

  getCourts(): Observable<any> {
    return this.http.get<any>(this.getCourtUrl)
      .pipe(
        tap(_ => console.log('fetched courts')),
        catchError(this.handleError<any[]>('getCourts', []))
      );
  }

  getUpcomingBookings(getUserID) {
    return this.http.post(this.upcomingBookingUrl, getUserID, {
      headers: this.header
    })
  }

  getTournamentList(){
    return this.http.get(this.tournamentUrl, {
      headers: this.header
    });
  }

  shareBooking(shareEmail) {
    return this.http.post(this.shareUrl, shareEmail, {
      headers: this.header
    })
  }

  addReview(reviewData) {
    return this.http.post(this.reviewUrl, reviewData, {
      headers: this.header
    })
  }

  verifyCoupon(couponCode) {
    return this.http.post(this.verifyCouponUrl, couponCode, {
      headers: this.header
    })
  }

  getUpcomingMatchs() {
    return this.http.get(this.upcomingMatchUrl, {
      headers: this.header
    })
  }
  
  cancelBooking(cancelBookingId) {
    return this.http.post(this.cancelBookingUrl, cancelBookingId, {
      headers: this.header
    })
  }

  getBookingDetails(bookingDetailId) {
    const bookingDtUrl = `${this.bookingDetailUrl}/?booking_id=${bookingDetailId}`;
    return this.http.get(bookingDtUrl, {
      headers: this.header
    })
  }

  removePlayerFromMatch(playerId) {
    const removePlayerUrl = `${this.removePlayer}/?id=${playerId}`;
    return this.http.get(removePlayerUrl, {
      headers: this.header
    })
  }

  getCourt(id: number): Observable<any> {
    const url = `${this.getSingleCourtUrl}/?id=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched single court id=${id}`)),
      catchError(this.handleError<any>(`get court error id=${id}`))
    );
  }

  getSingleCourt(corutId) {
    const singUrl = `${this.getSingleCourtUrl}/?id=${corutId}`;
    return this.http.get(singUrl)
  }

  getMatchDetail(matchId) {
    const url = `${this.matchDetailUrl}/?id=${matchId}`;
    return this.http.get(url, {headers: this.header})
  }

  bookCourtSlot(fullData) {
    return this.http.post(this.slotBookUrl, fullData, {
      headers: this.header
    })
  }

  createMatch(matchPostData) {
    return this.http.post(this.createMatchUrl, matchPostData, {
      headers: this.header
    })
  }

  getOpenMatches() {
    return this.http.get(this.openMatchesUrl, {
      headers: this.header
    })
  }
  contact(contactUser) {
    return this.http.post(this.contactUrl, contactUser, {
      headers: this.header
    })
  }
  getClubNews() {
    return this.http.get(this.clubNewsUrl, {
      headers: this.header
    })
  }

  getSlots(slotData) {
    return this.http.post(this.getSlotsUrl, slotData)
  }

  makePayment(paymentData) {
    return this.http.post(this.paymentUrl, paymentData, {
      headers: this.header
    })
  }

  paymentByGpo(gpoPaymentData) {
    return this.http.post(this.gpoPaymnetUrl, gpoPaymentData, {
      headers: this.header
    })
  }

  matchPaymentByGpo(gpoPaymentData) {
    return this.http.post(this.gpoMatchPaymentUrl, gpoPaymentData, {
      headers: this.header
    })
  }
  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}