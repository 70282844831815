import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { CourtDetailComponent, PaymentModal } from './court-detail.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS } from 'src/app/common/date-formats';
import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [CourtDetailComponent, PaymentModal],
  imports: [
    CommonModule,
    SwiperModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    MatNativeDateModule,
    FormsModule,
    MomentDateModule,
    MatCheckboxModule,
    MatMomentDateModule,
    MatDialogModule,
    MatRadioModule
  ],
  exports: [RouterModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ],

  bootstrap: [CourtDetailComponent]

})
export class CourtDetailModule { }
