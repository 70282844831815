import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../appModels/user.model';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ENDPOINTS } from '../endpoint';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loginUrl = ENDPOINTS.baseUrl+ENDPOINTS.userLogin;
  private socialLoginUrl = ENDPOINTS.baseUrl+ENDPOINTS.socialLogin;
  private registerUrl = ENDPOINTS.baseUrl+ENDPOINTS.userRegister;
  private sportUrl = ENDPOINTS.baseUrl+ENDPOINTS.sportsList;
  private countryListUrl = ENDPOINTS.baseUrl+ENDPOINTS.countryList;
  private countryUrl = ENDPOINTS.baseUrl+ENDPOINTS.country;
  private sponsorUrl = ENDPOINTS.baseUrl+ENDPOINTS.sponsor;
  private announcementsUrl = ENDPOINTS.baseUrl+ENDPOINTS.announcements;
  private sportLevelUrl = ENDPOINTS.baseUrl+ENDPOINTS.sportLevel;
  private ecommerceUrl = ENDPOINTS.baseUrl+ENDPOINTS.ecommerce;
  private forgotPasswordUrl = ENDPOINTS.baseUrl+ENDPOINTS.forgotPassword;  
  
  
  user = new BehaviorSubject<User>(null);
  loggedInUserName = '';
  loggedInUserImageUrl = '';


  ContentHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  });


  constructor(private http: HttpClient, private router: Router) { }

  loginUser(userData) {
    return this.http.post<any>(this.loginUrl, userData)
      .pipe(
        catchError(err => err),
        tap(res => {          
          this.authentucatedUser(
            res.data.id,
            res.data.profile_pic,
            res.data.email,
            res.data.first_name,
            res.data.token,
            res.data.level,
            res.data.mobile
          )
          this.loggedInUserName = res.data.first_name;
          this.loggedInUserImageUrl = res.data.profile_pic;
        })
      )
  }

  
  socialLoginUser({ socialUserData }: { socialUserData; }) {
    return this.http.post<any>(this.socialLoginUrl, socialUserData)
      .pipe(
        catchError(err => err),
        tap(res => {
          this.authentucatedUser(
            res.data.id,
            res.data.profile_pic,
            res.data.email,
            res.data.first_name,
            res.data.token,
            res.data.level,
            res.data.mobile
          )
          this.loggedInUserName = res.data.first_name;
          this.loggedInUserImageUrl = res.data.profile_pic;
        })
      )
  }

  registerUser(registerUser) {
    return this.http.post(this.registerUrl, registerUser, {
      headers: this.ContentHeader
    })
  }

  forgotUser(forgotEmail) {
    return this.http.post(this.forgotPasswordUrl, forgotEmail)
  }

  getSportList(){
    return this.http.get(this.sportUrl)
  }

  getSponsorList(){
    return this.http.get(this.sponsorUrl)
  }

  getEcommerceProducts(){
    return this.http.get(this.ecommerceUrl)
  }

  getAnnouncements(){
    return this.http.get(this.announcementsUrl)
  }

  getCountryList(){
    return this.http.get(this.countryListUrl)
  }

  getSportLevel(id: number): Observable<any> {
    const url = `${this.sportLevelUrl}?id=${id}`;
    return this.http.get<any>(url);
  }

  getSport(id: number): Observable<any> {
    const url = `${this.countryUrl}?id=${id}`;
    return this.http.get<any>(url);
  }

  signOut() {
    this.user.next(null);
    this.router.navigate(['']);
    localStorage.removeItem('loggedInUserData');
    // localStorage.removeItem('sportSelected');
    // localStorage.removeItem('selectedCountry');
  }

  autoSignIn() {
    const loggedInUserData = JSON.parse(localStorage.getItem('loggedInUserData'));
    if (!loggedInUserData) {
      return;
    }
    const loggedInUser = new User(
      loggedInUserData._id,
      loggedInUserData._profileImage,
      loggedInUserData._email,
      loggedInUserData._name,
      loggedInUserData._token,
      loggedInUserData._level,
      loggedInUserData._mobile
    );
    this.loggedInUserName = loggedInUserData._name;
    this.loggedInUserImageUrl = loggedInUserData._profileImage;
    this.user.next(loggedInUser);
  }

  private authentucatedUser(id, profilePic, email, name, token, level, mobile) {
    const user = new User(id, profilePic, email, name, token, level, mobile)
    this.user.next(user);
    localStorage.setItem('loggedInUserData', JSON.stringify(user))
  }

}
