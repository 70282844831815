import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ENDPOINTS } from 'src/app/endpoint';

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  private allPlayerUrl = ENDPOINTS.baseUrl+ENDPOINTS.allPlayer;
  private getSinglePlayerUrl = 'https://reservatti.com/admin/api/player-profile'
  private sendRequestUrl = ENDPOINTS.baseUrl+ENDPOINTS.sentRequests;
  private receivedRequestUrl = ENDPOINTS.baseUrl+ENDPOINTS.receivedRequest;
  private getFriendsUrl =  ENDPOINTS.baseUrl+ENDPOINTS.friends;
  private requestStatusUrl =  ENDPOINTS.baseUrl+ENDPOINTS.requestStatus;
  private chatUrl =  ENDPOINTS.baseUrl+ENDPOINTS.chats;
  private sendMessageUrl =  ENDPOINTS.baseUrl+ENDPOINTS.sendMessage;


  header = new HttpHeaders({
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('loggedInUserData'))._token
  });

  constructor(private http: HttpClient) { }

  getAllPlayer() {
    return this.http.get(this.allPlayerUrl, {
      headers: this.header
    })
  }

  getSinglePlayer(id: number): Observable<any> {
    const url = `${this.getSinglePlayerUrl}/?id=${id}`;
    return this.http.get(url, {
      headers: this.header
    })
  }

  getChatHistory(id: number): Observable<any> {
    const url = `${this.chatUrl}/?friend=${id}`;
    return this.http.get(url, {
      headers: this.header
    })
  }

  sendMessageService(sendMessagetData){
    return this.http.post(this.sendMessageUrl, sendMessagetData, {
      headers: this.header
    })
  }

  sendRequestService(sendRequestData){
    return this.http.post(this.sendRequestUrl, sendRequestData, {
      headers: this.header
    })
  }

  actionRequestService(requestStatusData){
    return this.http.post(this.requestStatusUrl, requestStatusData, {
      headers: this.header
    })
  }

  getRequestService() {
    return this.http.get(this.receivedRequestUrl, {
      headers: this.header
    })
  }

  getFriendsService() {
    return this.http.get(this.getFriendsUrl, {
      headers: this.header
    })
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}
