import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  showHead: boolean = false;

  constructor(private router: Router,
        private _authService: AuthService
    ) {
      router.events.forEach((event) => {
        if (event instanceof NavigationStart) {
          if (event['url'] == '/' || event['url'] == '/register' || event['url'] == '/forgot-password') {
            this.showHead = false;
          } else {
            this.showHead = true;
          }
        }
      });
  
  }

  ngOnInit(){
    this._authService.autoSignIn();
  }


}
